import "./w3.css"

/*import React from "react"

import { AuthProvider } from "./src/context/AuthProvider"
import Home from "./src/components/page/home"

/*export const wrapRootElement = ({ element }) => (
<AuthProvider>{element}</AuthProvider>
  
)
export const onInitialClientRender = () => {
  console.log("******************************************ReactDOM.render has executed??????????????????????????????????????????????????")
}
	/*export const wrapPageElement = ({ element, props }) => {
  
	  return <Home {...props}>{element}</Home>
	}*/
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location)
  const queriedPosition = getSavedScrollPosition({ pathname: `/random` })
	console.log(currentPosition,queriedPosition)
  window.scrollTo(...(currentPosition || [0, 0]))

  return false
}